import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './styles.scss';

interface QuickResponderBadgeProps {
	small?: boolean;
	shouldBold24h?: boolean;
}

const QuickResponderBadge = ({
	small,
	shouldBold24h = true,
}: QuickResponderBadgeProps) => (
	<div className={Styles.quickResponderBadge}>
		<Icon name={small ? 'timeline' : 'quick_reply'} size="sm" />
		{small ? (
			<Body2>
				Typically responds within {shouldBold24h ? <strong>24h</strong> : '24h'}
			</Body2>
		) : (
			<Body2>Typically responds within 24 hours</Body2>
		)}
	</div>
);

export default QuickResponderBadge;
