import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { State } from 'types/redux';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import type { Decorated } from 'types/vendor';
import AnalyticsConstants from '../../constants/analytics';
import { EVENT_CONTACT_VENDOR } from '../../lib/vendor-recommendation-module/utils/tracking/vrmTracking';
import VendorAnalytics from '../decorators/vendorAnalytics';
import { isSemanticSearchCalledAtom } from '../pages/VendorsSearch/containers/SemanticSearch/atoms';
import PageType = Page.PageType;

type buildAnalyticsPropertiesParams = {
	vendor: Decorated;
	pageType: PageType;
	sourceContent: InlineRfqSourceContent;
	isSemanticSearch?: boolean;
};
export interface useTrackContactVendorParams {
	vendor: Decorated | null;
	pageType: PageType;
	sourceContent: InlineRfqSourceContent;
	getExtraProperties?: () => object;
}

const buildAnalyticsProperties = ({
	vendor,
	pageType,
	sourceContent,
	isSemanticSearch,
}: buildAnalyticsPropertiesParams) => {
	const vendorPaid = vendor.purchaseStatus === 'PAID' ? 'paid' : 'unpaid';
	const sourcePage =
		pageType === 'storefront'
			? `${vendorPaid} storefront`
			: isSemanticSearch
				? AnalyticsConstants.SEMANTIC_RESULTS
				: 'category results';
	const vendorAnalytics = new VendorAnalytics(vendor);

	return {
		additionalVendors: false,
		hasWeddingVision: false,
		sourceContent,
		sourcePage,
		parentVendorId: vendor.id,
		leadFormType: 'direct',
		...vendorAnalytics.baseEventProps(),
	};
};

export const useTrackContactVendor = ({
	vendor,
	pageType,
	sourceContent,
	getExtraProperties,
}: useTrackContactVendorParams) => {
	const { track } = useAnalyticsContext();
	const memberId = useSelector((state: State) => state.membership.member?.id);
	const isSemanticSearch = useAtomValue(isSemanticSearchCalledAtom);

	const baseProperties = useMemo(() => {
		if (!vendor) return null;

		return buildAnalyticsProperties({
			vendor,
			pageType,
			sourceContent,
			isSemanticSearch,
		});
	}, [vendor, pageType, sourceContent, isSemanticSearch]);

	return useCallback(
		(conversationId, context?) => {
			if (!baseProperties) return;

			const properties = {
				...baseProperties,
				conversationId,
				userId: memberId,
				...(getExtraProperties ? getExtraProperties() : {}),
			};

			track(
				{
					event: EVENT_CONTACT_VENDOR,
					properties,
				},
				context,
			);
		},
		[baseProperties, track, getExtraProperties, memberId],
	);
};
