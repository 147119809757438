import { useEffect, useState } from 'react';
import type {
	OnBlur,
	PasswordRef,
} from '../../components/auto-account-creation/types';

export interface UseBlur {
	(passwordRef: PasswordRef): [OnBlur: OnBlur, ShouldValidateMinimum: boolean];
}

const useBlur: UseBlur = (passwordRef) => {
	const [shouldValidateMinimum, setShouldValidateMinimum] = useState(false);

	const onBlur = () => {
		if (!shouldValidateMinimum) {
			setShouldValidateMinimum(true);
		}
	};

	useEffect(() => {
		if (passwordRef.current) {
			passwordRef.current.focus();
			passwordRef.current.addEventListener('blur', onBlur);
		}
		return () => {
			if (passwordRef.current) {
				passwordRef.current.removeEventListener('blur', onBlur);
			}
		};
	}, [shouldValidateMinimum]);

	return [onBlur, shouldValidateMinimum];
};

export default useBlur;
