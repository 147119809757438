import { useCallback, useEffect, useRef, useState } from 'react';

const options: IntersectionObserverInit = {
	root: null,
	rootMargin: '0px',
	threshold: 1,
};

export const useTrackVisible = (track: () => void) => {
	const ref = useRef(null);
	const [, setHasTracked] = useState(false);

	const trackCallback = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			setHasTracked((prevState) => {
				if (prevState) return prevState;
				const tracked = Boolean(!prevState && entry.intersectionRatio === 1);

				if (tracked) {
					track();
				}

				return tracked;
			});
		},
		[track],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(trackCallback, options);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [trackCallback]);

	return ref;
};
