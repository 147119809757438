import NavigableModal from '@components/NavigableModal/NavigableModal';
import { reportServerSideExperiment } from '@redux/experiments';
import { isVendorWidgetOnRfqModalsSelector } from '@redux/experiments/selectors/vendor-widget-on-rfq-modals';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { compose } from '@xo-union/react-css-modules';
import React, { useEffect } from 'react';
import type { FCWithChildren } from 'types/react-extended';
import type { UseInlineRfqFormReturn } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../../../../utils';
import Styles from './RfqModalWrapper.scss';

const tabletModalClasses = compose({
	'button-container': Styles.tabletButtonContainer,
	'close-button': Styles.tabletCloseButton,
	large: Styles.tabletModal,
});

const mobileModalClasses = compose({
	container: Styles.container,
	content: Styles.content,
});

const mobileModalClassesControl = compose({
	container: Styles.container,
	content: Styles.content,
	'modal-overlay': Styles.modalOverlay,
});

export interface ModalWrapperProps {
	context: UseInlineRfqFormReturn;
	viewport: Redux.Viewport;
	onClose: () => void;
	isModalOnModal?: boolean;
}

export const RfqModalWrapper: FCWithChildren<ModalWrapperProps> = ({
	context,
	children,
	viewport,
	onClose,
	isModalOnModal = false,
}) => {
	const isVendorWidgetOnRfqModalsVariant = useAppSelector(
		isVendorWidgetOnRfqModalsSelector,
	);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isTabletOrMedium && !isMobile;

	const dispatch = useAppDispatch();

	useEffect(() => {
		// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
		console.log(
			'Vendor widget on RFQ modals assignment: ',
			isVendorWidgetOnRfqModalsVariant,
		);
		dispatch(reportServerSideExperiment(experiments.vendorWidgetOnRfqModals));
	}, [dispatch, isVendorWidgetOnRfqModalsVariant]);

	let classes: ReturnType<typeof compose>;
	if (isMobile) {
		classes = mobileModalClasses;
	} else if (isTabletOrMedium) {
		classes = tabletModalClasses;
	} else classes = compose({ small: Styles.containerDesktop });

	return (
		<NavigableModal
			hash="rfq-modal"
			isModalOpen={context.isModalOpen}
			closeCallback={onClose}
			classes={classes}
			size={isDesktop ? 'sm' : 'lg'}
			isModalOnModal={isModalOnModal}
		>
			{children}
		</NavigableModal>
	);
};
