import type { AvailabilityResponse } from '@components/calendar/types';
import {
	CALENDAR_RANGE_YEARS,
	fetchAvailability,
} from '@components/calendar/utils';
import { addMonths, addYears, startOfMonth } from 'date-fns';
import { atom } from 'jotai';

export const availabilityDataAtom = atom<AvailabilityResponse | null>(null);

type FetchAvailabilityParams = {
	vendorId: string;
};

export const fetchAvailabilityAtom = atom<
	null,
	[value: FetchAvailabilityParams],
	void
>(null, (get, set, value) => {
	const handleAvailabilityResponse = async (response: Response) => {
		if (response.status === 200) {
			set(availabilityDataAtom, await response.json());
		} else if (response.status === 204) {
			console.info('Vendor has not configured their availability.');
		} else {
			console.error(
				`Fetching availability failed with status code: ${response.status}`,
			);
		}
	};

	try {
		fetchAvailability({
			storefrontId: value.vendorId,
			startDate: new Date(),
			endDate: startOfMonth(addMonths(new Date(), 1)),
		}).then((initialResponse) => {
			handleAvailabilityResponse(initialResponse);

			if (initialResponse.status === 200) {
				// Fetch full range of availability data
				fetchAvailability({
					storefrontId: value.vendorId,
					startDate: new Date(),
					endDate: addYears(new Date(), CALENDAR_RANGE_YEARS),
				}).then(handleAvailabilityResponse);
			}
		});
	} catch (error) {
		console.error(`Fetching availability failed: ${error}`);
	}
});
