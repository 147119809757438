import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';
import { useStorefrontFeatures } from '../pages/Storefront/hooks/useStorefrontFeatures';

export const useShowAvailability = () => {
	const featureFlags = useStorefrontFeatures();
	const availabilityFeatureFlag =
		featureFlags[FEATURE_FLAGS.STOREFRONT_AVAILABILITY];
	const isPaidVendor = useAppSelector((state) => state.vendor.vendor?.isPaid);

	return useMemo(() => {
		return availabilityFeatureFlag && isPaidVendor;
	}, [availabilityFeatureFlag, isPaidVendor]);
};
