import { useEscape } from '@hooks/useEscape';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import InlineRFQ from '../../InlineRfq';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../hooks/useTrackInlineForm/use-track-inline-form';
import { RfqModalWrapper } from './components/RfqModalWrapper/RfqModalWrapper';

export interface OwnProps {
	context: ReturnType<typeof useInlineRfqForm>;
	handleCloseInlineRfq?: () => void;
	handleSubmittedInlineRfq?: () => void;
	isModalOnModal?: boolean;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type RfqModalProps = StateProps & OwnProps;

export const RfqModal: FC<RfqModalProps> = (props) => {
	const {
		context,
		viewport,
		handleCloseInlineRfq,
		handleSubmittedInlineRfq,
		isModalOnModal = false,
	} = props;
	const { isModalOpen, setIsModalOpen } = context;
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);

	const closeModal = () => {
		afterNextPaint(() => {
			trackCloseInlineForm();
			setIsModalOpen(false);
			handleCloseInlineRfq?.();
		});
	};
	useEscape(closeModal);

	if (isModalOpen) {
		return (
			<RfqModalWrapper
				context={context}
				viewport={viewport}
				onClose={closeModal}
				isModalOnModal={isModalOnModal}
			>
				<InlineRFQ
					context={context}
					handleSubmittedInlineRfq={handleSubmittedInlineRfq}
				/>
			</RfqModalWrapper>
		);
	}

	return null;
};

const mapStateToProps = (state: Redux.State) => ({
	viewport: state.viewport,
});

export default connect(mapStateToProps)(RfqModal);
