import { useAppSelector } from '@redux/hooks';
import type { ClassesMapper } from '@xo-union/react-css-modules';
import {
	AsyncTypeahead,
	type AsyncTypeaheadClasses,
} from '@xo-union/tk-component-fields';
import React, { useMemo, useState, type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';
import Styles from './TypeaheadInputField.scss';

export interface TypeaheadInputFieldProps {
	autoComplete: Rfq.InlineAutoCompleteAttributes;
	classes: ClassesMapper<AsyncTypeaheadClasses>;
	name: Rfq.InlineFieldKeys;
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
	suggestions?: string[];
	handleTypeaheadSearch?: (query: string) => void;
	removeWhitespaces?: boolean;
}

export const TypeaheadInputField: FC<TypeaheadInputFieldProps> = (props) => {
	const {
		autoComplete,
		classes,
		name,
		context,
		isRequired = false,
		suggestions = [],
		handleTypeaheadSearch = () => {},
		removeWhitespaces = false,
	} = props;
	const formFields = useAppSelector((state) => state.rfq.inline.fields);
	const { values: contextFields, setFieldValue, shouldShowErrors } = context;
	const [value, setValue] = useState<string>(formFields[name].value);
	const data = contextFields[name];

	// biome-ignore lint/correctness/useExhaustiveDependencies: input cannot be changed if we add "value" as dependency
	useMemo(() => {
		if (data.value && data.value !== value) {
			setValue(data.value);
		}
	}, [data.value]);

	const handleTypeaheadInputChange = (query: string) => {
		// FYI: query is automatically trimmed when passed to onSearch
		setValue(removeWhitespaces ? query.replace(/\s/g, '') : query);
	};

	return (
		// @ts-expect-error 'AsyncTypeahead' cannot be used as a JSX component
		<AsyncTypeahead
			autoComplete={autoComplete}
			classes={classes}
			label={getFieldLabel(data.label, isRequired)}
			name={autoComplete}
			value={value}
			defaultInputValue={value}
			onBlur={() => {
				handleFieldChange({
					cb: setFieldValue,
					data,
					name,
					value,
				});
			}}
			onInputChange={handleTypeaheadInputChange}
			suggestions={suggestions}
			onSearch={handleTypeaheadSearch}
			state={shouldShowErrors ? data.state : 'neutral'}
			subText={shouldShowErrors ? data.error : ''}
			renderSuggestion={(suggestion) => (
				<>
					<span className={Styles.suggestionMatchPart}>{value}</span>
					<span className={Styles.suggestionExtensionPart}>
						{suggestion.split(value)[1]}
					</span>
				</>
			)}
		/>
	);
};

export default TypeaheadInputField;
