import { DatePickerDropdownWrapper } from '@components/date-picker-dropdown-wrapper';
import { type ClassesMapper, compose } from '@xo-union/react-css-modules';
import type { DatePickerProps } from '@xo-union/tk-component-datepicker/lib/components/DatePicker';
import { Field } from '@xo-union/tk-component-fields';
import { format } from 'date-fns';
import React, { type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';
import Styles from './DatePickerField.scss';

const container = compose({ container: Styles.datePickerWrapper });
const DATE_FORMAT = 'yyyy-MM-dd';

export interface DatePickerFieldProps {
	datePickerStyle: ClassesMapper<string>;
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
}

export const DatePickerField: FC<DatePickerFieldProps> = (props) => {
	const { datePickerStyle, context, isRequired = false } = props;
	const { setFieldValue, shouldShowErrors, values } = context;
	const { weddingDate: contextValue } = values;

	const onDateChange: DatePickerProps['onChange'] = (event) => {
		const newDateValue = event.detail.value
			? format(event.detail.value, DATE_FORMAT)
			: '';

		handleFieldChange({
			cb: setFieldValue,
			data: contextValue,
			name: 'weddingDate',
			value: newDateValue,
		});
	};

	return (
		<>
			<DatePickerDropdownWrapper
				classes={container}
				value={
					contextValue.value?.length
						? new Date(contextValue.value.replace(/-/g, '/'))
						: undefined
				}
				disabled={[{ before: new Date() }, new Date()]}
				onChange={onDateChange}
				skeletonStyle={{ marginBottom: 16 }}
				renderInput={(moreProps) => (
					<Field
						classes={datePickerStyle}
						readOnly
						label={getFieldLabel(contextValue.label, isRequired)}
						name="weddingDate"
						value={contextValue.value}
						state={
							shouldShowErrors && contextValue.error !== ''
								? 'invalid'
								: 'neutral'
						}
						subText={shouldShowErrors ? contextValue.error : ''}
						{...moreProps}
					/>
				)}
			/>
		</>
	);
};

export default DatePickerField;
