import { useEffect } from 'react';

interface UseEscapeParams {
	(cb: () => void): void;
}

const useEscape: UseEscapeParams = (cb) => {
	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			cb();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);
};

export { useEscape };
