import { TooltipWrapper } from '@components/tooltip/TooltipWrapper';
import { useTrackVisible } from '@hooks/use-track-visible';
import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import noop from '@utils/noop';
import Icon from '@xo-union/tk-component-icons';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Styles from './why-use-knot.scss';

const BulletList = () => (
	<ul className={Styles.bulletList}>
		<li>
			<Body1>
				Messaging our verified vendors on The Knot is free, safe and secure.
			</Body1>
		</li>
		<li>
			<Body1>
				Conveniently track vendor messages and planning details all in one
				place.
			</Body1>
		</li>
		<li>
			<Body1>
				Our mobile apps make it easy to stay in touch with vendors while
				you&apos;re on&#8209;the&#8209;go.
			</Body1>
		</li>
		<li>
			<Body1>
				For personalized pricing and package details, sending the vendor a
				message is the fastest way to get info.
			</Body1>
		</li>
	</ul>
);

const useTrackTooltip = () => {
	const track = useTrackStorefrontInteraction('RFQ');

	const trackCallback = useCallback(() => {
		track('Tooltip interaction');
	}, [track]);

	return useTrackVisible(trackCallback);
};

export const buttonText = 'Why use The Knot to message vendors?';
export const WhyUseKnot = () => {
	const [active, setActive] = useState(false);
	const onToggle = useCallback((open: boolean) => {
		setActive(open);
	}, []);
	const tooltipRef = useTrackTooltip();

	return (
		<TooltipWrapper
			target={
				<div className={Styles.buttonWrapper}>
					<DisplayButton type="button" onClick={noop} className={Styles.text}>
						<Body2
							className={classNames(Styles.textWrapper, {
								[Styles.active]: active,
							})}
						>
							<Icon name="info" className={Styles.icon} size="sm" />
							{buttonText}
						</Body2>
					</DisplayButton>
				</div>
			}
			arrowPosition="bottom"
			onToggle={onToggle}
			className={Styles.tooltip}
		>
			<div ref={tooltipRef}>
				<BulletList />
			</div>
		</TooltipWrapper>
	);
};
