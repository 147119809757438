import { format, parseISO } from 'date-fns';

interface BuildCreationDateParams {
	timestamps: MessagedVendors.Timestamps[];
	vendorId: Vendor.Raw['id'];
}

export const buildCreationDate = ({
	timestamps,
	vendorId,
}: BuildCreationDateParams) => {
	const time = timestamps.find((timestamp) => timestamp.vendorId === vendorId);

	if (time) {
		const formattedTime = format(
			parseISO(time.conversationCreatedAt),
			'MM/dd/yy',
		)
			.split('/')
			.map((timeItem) => Number(timeItem))
			.join('/');
		return ` on ${formattedTime}`;
	}
	return '';
};

interface BuildUrlParams {
	conversations: MessagedVendors.Conversations;
	vendorId: Vendor.Raw['id'];
	inboxUrl: string;
}

export const buildUrl = ({
	conversations,
	vendorId,
	inboxUrl,
}: BuildUrlParams) => {
	if (Object.keys(conversations).length > 0) {
		const conversationId = conversations[vendorId];
		const url = `${inboxUrl}/${conversationId}`;
		return url;
	}
	return '#';
};
