import type { ClassesMapper } from '@xo-union/react-css-modules';
import { Field } from '@xo-union/tk-component-fields';
import React, { type FC, type ChangeEvent, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';

type OnChange = (e: ChangeEvent<HTMLInputElement>) => void;

export interface MaskPhoneFieldProps {
	classes: ClassesMapper<string>;
	context: ReturnType<typeof useInlineRfqForm>;
}

export const MaskPhoneField: FC<MaskPhoneFieldProps> = (props) => {
	const { classes, context } = props;
	const {
		setFieldValue,
		shouldShowErrors,
		values: { phoneNumber },
	} = context;
	const inputElementRef = useRef(null);

	const handleOnChange: OnChange = (event) => {
		const { value } = event.target;

		handleFieldChange({
			cb: setFieldValue,
			data: phoneNumber,
			name: 'phoneNumber',
			value,
		});
	};

	const mask = [
		'(',
		/[1-9]/,
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	];
	return (
		<div>
			<MaskedInput
				mask={mask}
				guide={false}
				onChange={handleOnChange}
				render={(ref, renderProps) => {
					const inputRef = (element) => {
						inputElementRef.current = element;
						ref(element);
					};

					return (
						<Field
							ref={inputRef}
							autoComplete="off"
							classes={classes}
							name="phoneNumber"
							inputMode="numeric"
							value={phoneNumber.value}
							state={shouldShowErrors ? phoneNumber.state : 'neutral'}
							subText={shouldShowErrors ? phoneNumber.error : ''}
							{...renderProps}
						/>
					);
				}}
			/>
		</div>
	);
};

export default MaskPhoneField;
