import RfqLegalCopy from '@components/RfqLegalCopy/RfqLegalCopy';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { reportServerSideExperiment } from '@redux/experiments';
import { emailDomainSuggesterSelector } from '@redux/experiments/selectors/email-domain-suggester';
import { isVendorWidgetOnRfqModalsSelector } from '@redux/experiments/selectors/vendor-widget-on-rfq-modals';
import { vendorWidgetPlacementSelector } from '@redux/experiments/selectors/vendor-widget-placement';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { IconButton } from '@xo-union/tk-component-icons';
import { Caption, Subhead } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import { VendorInfo } from '../bio/components/TeamInfo';
import Styles from './InlineRfq.scss';
import FormBody from './components/FormBody/FormBody';
import { HeaderSubText } from './components/HeaderSubText';
import LiteRFQ from './components/LiteRFQ/LiteRFQ';
import QuickResponderBadge from './components/QuickResponderBadge';
import SubmitCTA from './components/SubmitCTA';
import ViewConversation from './components/ViewConversation';
import { WhyUseKnot } from './components/WhyUseKnot';
import type { UseInlineRfqFormReturn } from './hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from './hooks/useTrackInlineForm/use-track-inline-form';
import {
	checkIsTablet,
	isPostPrimary,
	loadStoredRfqFormData,
	resolveButtonText,
} from './utils';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	context: UseInlineRfqFormReturn;
	handleSubmittedInlineRfq?: () => void;
}

type InlineRfqProps = StateProps & OwnProps;

const InlineRfq: FC<InlineRfqProps> = (props) => {
	const {
		conversations,
		vendorId = '',
		vendorRaw,
		context,
		handleSubmittedInlineRfq,
		inlineFormFields,
		viewport,
		categoryCode,
		membership,
	} = props;

	const trackEditInlineForm = useTrackInlineForm('edit', context.initiator);
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isMobile && !isTabletOrMedium;
	const vendorWidgetPlacementAssignment = useAppSelector(
		vendorWidgetPlacementSelector,
	);
	const isVendorWidgetPlacementExperiment =
		vendorWidgetPlacementAssignment !== 'control';

	const isInDetailedPricing = useDetailedPricing();

	const isVendorWidgetOnRfqModalsVariant = useAppSelector(
		isVendorWidgetOnRfqModalsSelector,
	);
	const emailDomainSuggesterVariant = useAppSelector(
		emailDomainSuggesterSelector,
	);

	const dispatch = useAppDispatch();

	const shouldShowScannability = useStorefrontFeatureScannability();

	useEffect(() => {
		if (vendorWidgetPlacementAssignment && shouldShowScannability) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Vendor Widget Placement experiment assignment: ',
				vendorWidgetPlacementAssignment,
			);
			dispatch(reportServerSideExperiment(experiments.vendorWidgetPlacement));
		}
	}, [vendorWidgetPlacementAssignment, shouldShowScannability]);

	const {
		isSubmitting,
		readyToSubmit,
		areErrorsInForm,
		shouldShowErrors,
		handleCtaClick,
		headerText,
		subheaderText,
		isModalOpen,
		isManuallyEdited,
		setIsModalOpen,
		handleSubmit,
		values,
		pageType,
	} = context;

	useEffect(() => {
		if (conversations[vendorId] && isModalOpen) {
			setIsModalOpen(false);
		} else {
			loadStoredRfqFormData({ context, categoryCode, membership });
		}
	}, []);

	useEffect(() => {
		if (emailDomainSuggesterVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Email domain suggester assignment: ',
				emailDomainSuggesterVariant,
			);
			dispatch(reportServerSideExperiment(experiments.emailDomainSuggester));
		}
	}, [emailDomainSuggesterVariant]);

	useEffect(() => {
		if (!isSubmitting && readyToSubmit) {
			handleSubmit().then((submitted) => {
				if (submitted) {
					handleSubmittedInlineRfq?.();
				}
			});
		}
	}, [inlineFormFields]);

	const [shouldHideLiteRFQ, setShouldHideLiteRFQ] = useState(false);

	const areErrorsInLiteRFQ = (): boolean => {
		const { firstName, lastName, emailAddress, weddingDate, guestCount } =
			values;

		return (
			firstName.error !== '' ||
			lastName.error !== '' ||
			emailAddress.error !== '' ||
			weddingDate.error !== '' ||
			guestCount.error !== ''
		);
	};

	const shouldRenderLiteRFQ = (): boolean => {
		const { firstName, lastName, emailAddress, weddingDate, guestCount } =
			values;
		// Checking the context should be enough to determine if the form is filled. See utils.ts/loadStoredRfqFormData for more info.
		const errorsInLiteRFQ = areErrorsInLiteRFQ();

		return (
			firstName.value !== '' &&
			lastName.value !== '' &&
			emailAddress.value !== '' &&
			weddingDate.value !== '' &&
			guestCount.value !== '' &&
			!errorsInLiteRFQ &&
			!shouldHideLiteRFQ &&
			!isManuallyEdited
		);
	};

	const renderLiteRFQ = useMemo(() => {
		return shouldRenderLiteRFQ();
	}, [shouldRenderLiteRFQ]);

	const shouldDisplayVendorWidget = useMemo(() => {
		const hasBioContent =
			vendorRaw?.bio?.name &&
			vendorRaw?.bio?.displayRole &&
			vendorRaw?.bio?.photo?.url;
		const shouldDisplayOnModals =
			isVendorWidgetOnRfqModalsVariant && isModalOpen;
		const shouldDisplayOnMobile = pageType === 'storefront';
		const shouldDisplayOnDesktop =
			!isModalOpen && isVendorWidgetPlacementExperiment;

		return (
			hasBioContent &&
			(shouldDisplayOnModals ||
				(isMobile ? shouldDisplayOnMobile : shouldDisplayOnDesktop))
		);
	}, [
		vendorRaw?.bio,
		isMobile,
		pageType,
		isModalOpen,
		isVendorWidgetPlacementExperiment,
		isVendorWidgetOnRfqModalsVariant,
	]);

	const shouldRenderQuickResponderBadge = Boolean(
		isDesktop &&
			vendorRaw?.vendorBehavior?.quickResponder &&
			(!shouldDisplayVendorWidget ||
				!isVendorWidgetPlacementExperiment ||
				vendorWidgetPlacementAssignment === 'Widget_RFQ_body'),
	);

	if (
		isDesktop &&
		isPostPrimary(
			conversations,
			vendorId,
			isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return <ViewConversation />;
	}

	const handleCloseModal = () => {
		trackCloseInlineForm();
		setIsModalOpen(false);
	};

	return (
		<>
			<div
				className={classNames({
					[Styles.mobileWrapper]: isMobile && !isTabletOrMedium,
					[Styles.tabletWrapper]: isTabletOrMedium,
					[Styles.inlineSticky]: isDesktop && !isModalOpen,
				})}
				data-detailed-pricing-rfq={isInDetailedPricing}
				data-scannability={shouldShowScannability}
			>
				<div
					className={classNames({
						[Styles.inlineWrapper]: isDesktop && !isModalOpen,
					})}
				>
					<div
						className={classNames({
							[Styles.basePadding]: isDesktop && !isModalOpen,
						})}
					>
						{isTabletOrMedium && (
							<div
								className={classNames({
									[Styles.headerCloseButton]: isTabletOrMedium,
								})}
							>
								<IconButton
									aria-label="close"
									block
									name="close"
									onClick={handleCloseModal}
									size="sm"
								/>
							</div>
						)}
						<div className={Styles.headerTextWrapper}>
							<Subhead bold>{headerText || 'Message vendor'}</Subhead>
							<Caption className={Styles.requiredText}>*=Required</Caption>
						</div>
						<HeaderSubText
							isMobile={isMobile}
							isTabletOrMedium={isTabletOrMedium}
							pricingText={subheaderText}
						/>
						{shouldDisplayVendorWidget && (
							<VendorInfo
								bio={vendorRaw?.bio}
								isInLiteRFQ={true}
								includeQuickResponderBadge={Boolean(
									vendorRaw?.vendorBehavior?.quickResponder &&
										vendorWidgetPlacementAssignment !== 'Widget_RFQ_body',
								)}
							/>
						)}
						{!renderLiteRFQ && <FormBody context={context} />}
						{renderLiteRFQ && (
							<LiteRFQ
								context={context}
								buttonHandler={() => {
									trackEditInlineForm();
									setShouldHideLiteRFQ(true);
								}}
							/>
						)}
						{!shouldShowScannability && !renderLiteRFQ && (
							<div className={Styles.avoidStickyCover}>
								<WhyUseKnot />
							</div>
						)}
					</div>
					<div
						className={classNames({
							[Styles.stickyCta]: isMobile,
							[Styles.desktopCta]: isDesktop && !isModalOpen,
							[Styles.stickyDesktopCta]: isDesktop && isModalOpen,
						})}
					>
						{!membership.member && (
							<div
								className={
									isModalOpen
										? Styles.legalCopyContainerModal
										: Styles.legalCopyContainer
								}
							>
								<RfqLegalCopy
									btnText={resolveButtonText({
										catCode: categoryCode,
										isMobile,
										isTablet: isTabletOrMedium,
									})}
								/>
							</div>
						)}
						<SubmitCTA handleSubmit={handleCtaClick} />
						{shouldRenderQuickResponderBadge && (
							<div className={Styles.quickResponderBadgeWrapper}>
								<QuickResponderBadge small={shouldShowScannability} />
							</div>
						)}
						{membership.member && (
							<div className={Styles.termsContainer}>
								<a
									href="https://www.theknotww.com/terms-of-use"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Terms of Use
								</a>
								<a
									href="https://www.theknotww.com/privacy-policy"
									rel="noopener noreferrer"
									target="_blank"
									className={Styles.termsLink}
								>
									Privacy Policy
								</a>
							</div>
						)}
					</div>
				</div>
				{shouldShowScannability && (
					<div className={Styles.whyUseKnotWrapper}>
						<WhyUseKnot />
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	inlineFormFields: state.rfq.inline.fields,
	categoryCode: state.category.code,
	membership: state.membership,
	conversations: state.messagedVendors.conversations,
	vendorRaw: state.vendor.vendorRaw,
	vendorId: state.vendor.vendorRaw?.id,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(InlineRfq);
