import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React, { useMemo, type FC } from 'react';
import { connect } from 'react-redux';
import type { Decorated } from 'types/vendor';
import { getPricingText } from '../../../../helpers';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	className?: string;
	isSingleLine?: boolean;
	pricingText?: JSX.Element | string;
}

export type PriceRangeProps = StateProps & OwnProps;

export const PriceRange: FC<PriceRangeProps> = (props) => {
	const { className = '', isSingleLine, vendor, pricingText } = props;

	const text = useMemo(() => {
		return getPricingText(vendor);
	}, [vendor]);

	if (pricingText) {
		return <Body1 className={Styles.pricingText}>{pricingText}</Body1>;
	}

	if (!isSingleLine && text.includes(':')) {
		return (
			<div className={className}>
				<Body2>{text}</Body2>
			</div>
		);
	}

	return <Body1 className={className}>{text}</Body1>;
};

const mapStateToProps = (state: Redux.State) => ({
	vendor: state.vendor.vendor as Decorated,
});

export default connect(mapStateToProps)(PriceRange);
