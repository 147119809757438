import { State } from '@xo-union/tk-component-fields';
import { useEffect } from 'react';
import AnalyticsConstants from '../../../constants/analytics';
import type {
	Analytics,
	ButtonIsDisabled,
	OnBlur,
} from '../../components/auto-account-creation/types';

const { PASSWORD_CREATE, SAVE_PASSWORD } = AnalyticsConstants;

interface UseKeypress {
	(
		onBlur: OnBlur,
		handleSumit: (analytics: Analytics) => void,
		isValidPassword: (blur: boolean) => State,
	): [ButtonIsDisabled];
}

const useKeypress: UseKeypress = (onBlur, handleSubmit, isValidPassword) => {
	const onKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			onBlur();
			if (!buttonIsDisabled()) {
				handleSubmit({ sourceContent: PASSWORD_CREATE, action: SAVE_PASSWORD });
			}
		}
	};

	const buttonIsDisabled = () => isValidPassword(false) === 'invalid';

	useEffect(() => {
		document.addEventListener('keypress', onKeyPress);

		return () => {
			document.removeEventListener('keypress', onKeyPress);
		};
	}, [buttonIsDisabled]);

	return [buttonIsDisabled];
};

export default useKeypress;
