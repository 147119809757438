import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useIsVenue } from '@hooks/use-is-venue';
import React from 'react';
import PriceRange from './PriceRange';
import Styles from './header-sub-text.scss';

interface Props {
	isMobile: boolean;
	isTabletOrMedium: boolean;
	pricingText?: JSX.Element | string;
}

export const HeaderSubText = ({
	isMobile,
	isTabletOrMedium,
	pricingText,
}: Props) => {
	const isInDetailedPricing = useDetailedPricing();
	const isVenue = useIsVenue();

	if ((isMobile || isTabletOrMedium) && !isVenue && !isInDetailedPricing) {
		return null;
	}

	return (
		<PriceRange
			className={Styles.headerSubText}
			pricingText={pricingText}
			isSingleLine
		/>
	);
};
