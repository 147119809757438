import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';

const PAGES_TO_APPLY_ANDROID_STYLING = [
	'city',
	'state',
	'category',
	'review wedding vendors',
	'best of weddings',
];

export const useAndroidOnMobileByPage = () => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const pageType = useAppSelector((state) => state.page.pageType);
	const includedPage = PAGES_TO_APPLY_ANDROID_STYLING.includes(pageType);

	const isAndroidOnMobile = useMemo(() => {
		return /Android/i.test(navigator.userAgent) && isMobile && includedPage;
	}, [isMobile, includedPage]);

	return isAndroidOnMobile;
};
